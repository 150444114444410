<template>
  <div class="error">
    Error
  </div>
</template>

<script>
export default {
  name: 'Error'
}
</script>

<style lang="scss" scoped>

</style>